@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Sofia+Sans+Extra+Condensed:wght@500;600&display=swap');

$white: #ffffff;
$dynamicColor: #fcf300 !default;
$gray_def: #b1bcbc !default;
$gray_hover: #9aa4a4;
$gray_dark: #464646;
$bg-body: #010101;
$img-dir: '/./assets/images/';
$sofia: 'Sofia Sans Extra Condensed';


h1, h2, h3, h4, h5, h6 {
    color: $gray_def;
    white-space: pre-wrap;
}

p {
    font-size: 18px;
    color: $gray_def;
    white-space: pre-wrap;
}

a {
    color: $gray_def !important;
    text-decoration: none;

    &:hover {
        color: $gray_hover !important;
    }
}


