
div#topHeaderWithImage {
    background-image: url($img-dir + 'main_bg.png');
    background-position: top 0 right;
    background-repeat: no-repeat;
    background-size: 80%;


}

.kk-text-shadow {
    -webkit-text-shadow: 1px 1px 0px $bg-body;
    text-shadow: 1px 1px 0px $bg-body;
}

.kk-main-hp-text-container {
    margin-top: 160px;
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
        margin-top: 100px;
    }

    h1 {
        &.kk-title-name,
        &.kk-title-second-name {
            font-family: $sofia;
            font-size: 236px;
            font-weight: 600;
            height: 155px;
            line-height: 0.72;
            margin: 0;
            padding: 0;
            color: $dynamicColor;

            @include media-breakpoint-down(sm) {
                font-size: 180px;
                height: 115px;
            }
        }
    }

    .kk-title-hello {
        font-family: $sofia;
        font-size: 50px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        color: $gray_def;
    }


    .kk-title-pro-dev {
        position: absolute;
        bottom: -12px;
        left: 284px;
        font-family: $sofia;
        font-size: 107px;
        font-weight: 600;
        line-height: 0.8;
        margin: 0;
        padding: 0;
        color: $gray_def;

        @include media-breakpoint-down(lg) {
            left: 36px;
            bottom: -190px;
        }
        @include media-breakpoint-down(sm) {
            font-size: 67px;
            bottom: -130px;
        }
    }
}

.kk-main-hp-about-container {
    position: relative;
    overflow: hidden;

    .kk-bg-lines-holder {
        position: absolute;
        top: 100px;
        left: -500px;
        width: 955px;
        height: 1032px;
        z-index: -1;

        @include media-breakpoint-only(md) {
            left: -600px;
        }
    }


    .kk-banners-container {
        position: absolute;
        right: 0;
        top: 40;
        text-align: right;
        padding: 0 16px;

        .kk-banners-item-holder {
            margin: 180px 0 160px 0;
        }

        .kk-side-banners-line {
            width: 4px;
            height: 72px;
            background-color: $gray_def;
        }
    }

    .kk-gutter-before-about{
        margin-top: 280px;

        @include media-breakpoint-down(lg){
            margin-top: 400px;
        }
    }

    .kk-title-about,
    .kk-title-me {
        font-family: $sofia;
        font-size: 120px;
        font-weight: 500;
        line-height: 0.8;
        margin: 0;
        padding: 0;

        @include media-breakpoint-down(sm) {
            font-size: 92px;
        }
    }

    .kk-title-me {
        color: $gray_def;
    }

    .kk-cv-container {
        margin-top: 100px;

        .kk-cv-logos {
            padding-inline-end: 48px;
        }
    }
}


.kk-side-banners-text,
.kk-footer-end-page-text {
    font-family: $sofia;
    font-size: 24px;
    font-weight: 600;
    line-height: .8;
    margin: 0;
    padding: 0;
    color: $gray_dark;
}


